import React from "react"
import { graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const aboutme = ({data}) => (
    <Layout>
        <SEO title="About Me" />
        <div className="AboutHero">
            <div className="Avatar">
                <Img fluid={data.aboutHero.childImageSharp.fluid} alt="MQQ Avatar" 
                />
            </div>
        </div>
        <div className="AboutPageContent">
            <h1>Hi, nice to meet you!</h1>
            <p>My name is Manqian (pronounced as "man-chain", you can associate the sound with "mansion" ). I am a Digital Product Designer currently living in San Francisco Bay Area. Born and raised in <a href="https://wikitravel.org/en/Wenzhou">Wenzhou</a>, an eastern Chinese city where people speak the most challenging Chinese dialect (a.k.a. the <a href="https://www.wsj.com/articles/BL-CJB-22302">Devil-Language</a>).</p>

            <p>I solve complex design problems and bring users simplicity and thoughtfulness. I use design thinking and craft skills to shape the product for a successful business. Currently, I am working at <a href="https://www.coursera.org/">Coursera</a> to develop a scalable platform to serve the world through learning. </p>

            <p>My previous experience designing at <a href="https://www.inkling.com/products/inkling-learning-pathways/">Inkling</a> grew my product thinking skills from building a product from 0 to 1, where I enjoyed design autonomy, close collaboration with cross-functional teams as well as bubble teas.</p> 

            <p>Before Inkling I served at <a href="https://www.veeva.com/products/sunrise-ui/">Veeva</a> to help re-shape the 10-year old complex enterprise app. I was dedicated to transforming a complicated clunky system into a friendly and efficient tool that people love to use.</p>

            <p>Lastly, here are things I value as a designer:</p>
            <ul className="AboutList">
                        <li>Design quality & product proud</li>
                        <li>Design leadership & strategy</li>
                        <li>Cross-functional collaboration</li>
                        <li>Design community</li>
            </ul>

            <p>Thanks for visiting! Stay well and be optimistic! <span role="img" aria-label="Slightly Smiling Face">🙂</span></p>
        </div>
        <div className="AboutPageContact">
            <a className="AboutPageContactLinks" href="mailto: qianmanqian@gmail.com">Email</a> | <a href="https://www.linkedin.com/in/manqian-qian/">LinkedIn</a> | <a href="https://medium.com/@manqian">Medium</a>

        </div>
    </Layout>
)


export default aboutme

export const query = graphql`
query {
    aboutHero: file(relativePath: {eq: "hi-me.png"}) {
      childImageSharp {
        fluid(maxWidth: 250) {
         ...GatsbyImageSharpFluid
        }
      }
    }
    aboutHeroHover: file(relativePath: {eq: "me-hand.png"}) {
        childImageSharp {
          fluid(maxWidth: 250) {
           ...GatsbyImageSharpFluid
          }
        }
      }


}
`